import React, { useState, useEffect } from 'react';
import { ToastContainer, toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';

const Login = () => {

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [isLoading,setIsLoading] = useState(false);
  const navigate = useNavigate();

  const regexEmail = /^([a-zA-Z0-9_\-\.]+)@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.)|(([a-zA-Z0-9\-]+\.)+))([a-zA-Z]{1,5}|[0-9]{1,3})(\]?)$/

  useEffect(() => {
    const verifyToken = async () => {
      const token = localStorage.getItem('token');
      if (!token) {
        return;
      }
      try {
        const response = await fetch('https://api.manager.achieve.apars.shop/validate-token', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`
          }
        });
        if (response.ok) {
          navigate('/sheet')
        } else {
           localStorage.removeItem("token");
        }
      } catch (error) {
        console.error('Error validating token:', error);
      }
    };

    verifyToken();
  }, []);

  const handleSubmit = () => {
    if (regexEmail.test(email)) {
      const id = toast.loading("Logging in...")
      const fetchData = async () => {
        try {
          const response = await fetch('https://api.manager.achieve.apars.shop/login', {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                email: email,
                password: password
            })
          });
          const jsonData = await response.json();
          localStorage.setItem('token', jsonData.token);
          localStorage.setItem('branch', JSON.stringify(jsonData.branch));
          toast.update(id, { render: `${jsonData.message}`, type: "success", isLoading: false });
          navigate('/sheet');   
        } catch (error) {
          toast.update(id, { type: "error", autoClose: 3000, render: "Couldn't log in" , isLoading: false })
        }
      };
      fetchData();
    }
    else {
      toast.error("Please enter correct email!")
    }
  }

  return (
  <div className="flex items-center justify-center h-screen">
    <ToastContainer position="top-center" autoClose={1500} limit={1}/>
    <div>
    <img src={require('../images/logo copy.png')} alt="logo" className="h-10 w-26 block mx-auto" />
    <br /><br />
      <p className="text-xl md:text-2xl font-bold text-center">Login</p>
      <br />

        <label htmlFor="email" className="block mb-2 text-md font-medium text-gray-900">Your email</label>
        <input type="text" placeholder="Email" className="bg-gray-100 border border-gray-300 rounded-lg focus:outline-none focus:ring focus:ring-blue-400 focus:border-blue-400 block w-full p-2 my-4" value={email} onChange={(e) => { setEmail(e.target.value) }} required />

        <label htmlFor="password" className="block mb-2 text-md font-medium text-gray-900">Your password</label>
        <input type="password" className="bg-gray-100 border border-gray-300 rounded-lg focus:outline-none focus:ring focus:ring-blue-400 focus:border-blue-400 block w-full p-2 my-4" placeholder="Password" value={password} onChange={(e) => { setPassword(e.target.value) }} required />
        <br />

        <button onClick={handleSubmit} className="bg-blue-400 text-white text-center w-80 px-4 py-2 rounded-lg hover:ring hover:ring-blue-500 hover:shadow-2xl">Login</button>
      
    </div>
      </div>
  );
};

export default Login;
