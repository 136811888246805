import { useState } from 'react';
import NavBar from './components/Navbar';
import Sidebar from './components/Sidebar';
import ViewVarsity from './components/ViewVarsity'
import ViewMedical from './components/ViewMedical'
import ViewAgri from './components/ViewAgri'
import ViewAllAttendees from './components/ViewAllAttendees';
import SelectBatch from './components/SelectBatch';
import ViewEngineering from './components/ViewEngineering';

function Dashboard() {
  const [ viewEngineering, setviewEngineering] = useState(true);
  const [ viewVarsity, setviewVarsity] = useState(false);
  const [ viewMedical, setviewMedical] = useState(false);
  const [ viewAgri, setviewAgri] = useState(false);
  const [ viewattendees, setviewattendees ] = useState(false); 
  const [selectedBranchOption, setSelectedBranchOption] = useState('');
  const [selectedBatch, setSelectedBatch] = useState('');
  const [selectedTime, setSelectedTime] = useState('');
  const [searchRoll, setSearchRoll] = useState('')

  return (
    <div>
      <NavBar></NavBar>
      {/* <SelectBatch selectedBranchOption={selectedBranchOption} selectedBatch={selectedBatch} selectedTime={selectedTime} setSelectedBranchOption={setSelectedBranchOption} setSelectedBatch={setSelectedBatch} setSelectedTime={setSelectedTime} searchRoll={searchRoll} setSearchRoll={setSearchRoll}></SelectBatch> */}
      <div className='grid grid-cols-1 p-4 md:p-12 gap-4 md:grid-cols-4 md:gap-8' >
      <div className=''>
        <Sidebar setviewAgri={setviewAgri} setviewEngineering={setviewEngineering} setviewMedical={setviewMedical} setviewVarsity={setviewVarsity} setviewattendees={setviewattendees} ></Sidebar>
      </div>
      <div className='col-span-3'>
        { viewEngineering && <ViewEngineering></ViewEngineering>  }
        { viewVarsity && <ViewVarsity></ViewVarsity>}
        { viewMedical && <ViewMedical></ViewMedical>}
        { viewAgri && <ViewAgri></ViewAgri>}
        {viewattendees && <ViewAllAttendees></ViewAllAttendees> }
      </div>
    </div>  
    </div>
  );
}

export default Dashboard;
