import React from 'react';
import { PiStudent } from "react-icons/pi";
import { RiFileList3Line } from "react-icons/ri";
import { BsQrCodeScan } from "react-icons/bs";
import { Link } from "react-router-dom";

const Sidebar = ({setviewAgri, setviewMedical, setviewEngineering, setviewVarsity, setviewattendees }) => {

  const handleviewEngineering = () => {
    setviewEngineering(true)
    setviewattendees(false)
    setviewMedical(false)
    setviewAgri(false)
    setviewVarsity(false)
  }

  const handleviewVarsity = () => {
    setviewEngineering(false)
    setviewattendees(false)
    setviewMedical(false)
    setviewAgri(false)
    setviewVarsity(true)
  }

  const handleviewMedical = () => {
    setviewEngineering(false)
    setviewattendees(false)
    setviewMedical(true)
    setviewAgri(false)
    setviewVarsity(false)
  }

  const handleviewAgri = () => {
    setviewEngineering(false)
    setviewattendees(false)
    setviewMedical(false)
    setviewAgri(true)
    setviewVarsity(false)
  }

  const handleviewattendees = () => {
    setviewattendees(true)
    setviewEngineering(false)
    setviewattendees(false)
    setviewMedical(false)
    setviewAgri(false)
  }

  return (
    <div className='bg-stone-300 p-8 rounded-2xl'>
      
      <button className='bg-white p-2 shadow-2xl rounded-xl transition-transform duration-300 transform hover:scale-110' onClick={handleviewattendees} > <PiStudent className='inline-block' size={24}/> See statistics </button>
      <br />
      <br />

      <button className='bg-white p-2 shadow-2xl rounded-xl transition-transform duration-300 transform hover:scale-110' onClick={handleviewEngineering}> <RiFileList3Line className='inline-block' size={24}/> Engineering Exam sheet</button>
      <br />
      <br />
      <button className='bg-white p-2 shadow-2xl rounded-xl transition-transform duration-300 transform hover:scale-110' onClick={handleviewVarsity}> <RiFileList3Line className='inline-block' size={24}/> Varsity Exam sheets</button>
    <br />
    <br />
    <button className='bg-white p-2 shadow-2xl rounded-xl transition-transform duration-300 transform hover:scale-110' onClick={handleviewMedical}> <RiFileList3Line className='inline-block' size={24}/> Medical Exam sheets</button>
    <br />
    <br />
    <button className='bg-white p-2 shadow-2xl rounded-xl transition-transform duration-300 transform hover:scale-110' onClick={handleviewAgri}> <RiFileList3Line className='inline-block' size={24}/> Agri Exam sheets</button>
    </div>
  );
};

export default Sidebar;