import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Modal from 'react-modal';
import { ToastContainer, toast } from 'react-toastify';

const MakeAttendenceSheet = () => {
  const navigate = useNavigate();
  const [modalIsOpen, setIsOpen] = useState(false);
  const [ examName, setExamName] = useState('');
  const [date, setDate] = useState('');
  const [ batchName, setBatchName] = useState('');


  return (
    <div className='flex items-center justify-center h-screen'>
      <ToastContainer position="top-center" autoClose={1500} limit={1}/>
      <div>
        <p className='text-center font-bold text-xl'>
          Welcome, Invigilator!
        </p>
        <br />
        <br />
        <button onClick={() => navigate('/dashboard')} className='bg-stone-400 py-2 px-8 shadow-2xl rounded-xl transition-transform duration-300 transform hover:scale-110'> View attendance sheet</button>
 
      </div>
    </div>
  );
};

export default MakeAttendenceSheet;