import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

const ViewEngineering = ({selectedBranchOption, selectedTime, searchRoll, selectedBatch}) => {
  const [sheetData, setSheetData] = useState([]);
  const [loading, setLoading] = useState(true); // Added loading state
  const navigate = useNavigate();

  useEffect(() => {
    fetch('https://api.manager.achieve.apars.shop/agri') 
    .then(response => response.json())
    .then(data => {
      setSheetData(data);
      setLoading(false); // Set loading to false after data is fetched
    })
    .catch(error => {
      console.error('Error fetching user:', error);
      setLoading(false); // Set loading to false even if there's an error
    });
  },[])

  const today = new Date();
  today.setHours(0, 0, 0, 0); // Reset time for date-only comparison (local time)

  const threeDaysAgo = new Date(today);
  threeDaysAgo.setDate(today.getDate() - 3);

  const filteredData = sheetData.filter((item) => {
    const itemDate = new Date(item.date);
    itemDate.setHours(0, 0, 0, 0);
    return itemDate >= threeDaysAgo && itemDate <= today;
  });

  return (
    <div className="">
      <br />
      <p className='font-bold text-xl text-center'>All Attendance sheets of Agri Batch</p>
      <br />
      {loading ? (
        <p>Loading...</p> // Display loading when data is being fetched
      ) : filteredData.length > 0 ? (
        filteredData.map((data) => (
          <div key={data.id} className="bg-white shadow-md rounded-md p-4 mb-4 flex flex-col md:flex-row justify-center items-center md:justify-between">
            <p>{data.date.slice(0, 10)}</p>
            <div>
              <h2 className="text-lg font-semibold">{data.name} </h2>
              <p className="text-gray-600">{data.type}</p>
            </div>
            <div className=''>
              <button onClick={() => navigate(`/scanner?exam=${data._id}`)} className='bg-stone-400  p-2 rounded-lg transition-transform duration-300 transform hover:scale-110 shadow-lg'>
                Give attendance
              </button>
            </div>
          </div>
        ))
      ) : (
        <p>No exam today</p> // Display this when filtered array is empty
      )}
    </div>
  );
};

export default ViewEngineering;
